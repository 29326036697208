<template>
    <div class="news">
        <div class="title">{{ title }}</div>
        <div class="time">{{ time }}</div>
        <div class="art">
            <p v-for="(item,index) in art_content" :key="index" :class="item.kind">{{ item.content }}</p>
        </div>
        <div class="change_page">
            <div>上一篇：
                <div>
                    <p v-if="last<0">无</p>
                    <router-link v-if="last>=0" :to="'/news/'+last">{{ jsonstr[last].title }}</router-link>
                </div>
            </div>
            <div>下一篇：
                <div>
                    <div v-if="next>=jsonstr.length">无</div>
                    <router-link v-if="next<jsonstr.length" :to="'/news/'+next">{{ jsonstr[next].title }}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import json from "../../assets/news.json";
    export default {
        name: 'news',
        props: {
            top_list: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                id: this.$route.params.id,
                title: '',
                time: '',
                art_content: '',
                last: 0,
                next: 0,
                jsonstr: '',
            }
        },
        created() {
            let article = json[this.$route.params.id];
            this.title = article.title;
            this.time = article.time;
            this.art_content = article.art;
            this.last = this.id - 1;
            this.next = Number(this.id) + 1;
            this.jsonstr = json;
        },
        methods: {
            flash() {
                //  this.$router.push("/news/"+this.last); 
            },
            
        },

    }
</script>
<style lang="less">
    .news {
        .title {
            line-height: 55px;
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            color: #333;
            margin-top: 20px;
        }

        .time {
            line-height: 35px;
            text-align: center;
            font-size: 14px;
            color: #999;
        }

        .art {
            padding: 20px 0 50px 10px;
            border-top: 1px solid #CCC;
            border-bottom: 1px solid #CCC;
        }

        p {
            color: #333;
            line-height: 2;
        }

        .indent {
            text-indent: 2em;
        }

        .bold {
            font-weight: bold;
            line-height: 2.5rem;
            margin-top: 10px;
        }

        .from {
            text-align: right;
            line-height: 2.5;
        }

        .red {
            color: #c00000;
            font-weight: bold;
            margin-top: 50px;
        }

        .change_page {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #000;
            font-size: 14px;
            margin-top: 20px;

            a {
                color: blue;
                text-decoration: underline;
            }

            div {
                display: flex;
                align-items: center;
            }
        }
    }
</style>